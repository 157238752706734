import React, { useEffect } from 'react';

const AndroidRetailAppDownload = () =>{
    const handleRedirect = () => {
        window.location.replace(`https://kuda-banks-collections.s3.eu-west-2.amazonaws.com/Kuda+-+Transfers+and+Payments.apk`);
    }

    useEffect(() => {
        handleRedirect()
    }, [])
    return (
        <div class="full-preLoader">
            <div class="infinite-path">
                <span id="elem" class="shape trail"></span>
            </div>
        </div>
    )
}

export default AndroidRetailAppDownload